import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-divider',
    templateUrl: './page-divider.component.html',
    styleUrls: ['./page-divider.component.scss']
})
export class PageDividerComponent implements OnInit {

    @Input() text?: string;
    @Input() icon?: string = 'fa-solid fa-star';

    constructor() { }

    ngOnInit() {
    }

}
