import { Component } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';


@Component({
  selector: 'app-user-invite-modal',
  templateUrl: './assignment-summary-modal.component.html',
  styleUrls: ['./assignment-summary-modal.component.scss']
})
export class AssignmentSummaryModalComponent {
  assignment!: AssignmentsOverviewModel;

  constructor() { }
}
