<div class="d-flex gap-3 align-items-center">
    <div class="flex-grow-1">
        <hr class="m-0">
    </div>
    <div class="text-primary">
        <i [ngClass]="[icon, 'pe-2']"></i> <b>{{text}}</b>
    </div>
    <div class="flex-grow-1">
        <hr class="m-0">
    </div>
</div>