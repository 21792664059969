import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { Router } from '@angular/router';
import { AssignmentSummaryModalComponent } from '../assignment-summary-modal/assignment-summary-modal.component';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-assignments-list',
  templateUrl: './assignments-list.component.html',
  styleUrl: './assignments-list.component.scss'
})
export class AssignmentListComponent implements OnInit {
  @Input() eventType: AssignmentEventType | null = null;
  @Input() assignmentSubject?: BehaviorSubject<AssignmentsOverviewModel[] | null>;
  @Input() isListReloaded?: BehaviorSubject<boolean | null>;
  @Output() assignmentsSelected = new EventEmitter<AssignmentsOverviewModel[]>();

  selectedAssignments: Set<number> = new Set();
  assignmentEventType = AssignmentEventType;
  itemRole = '';

  constructor(
    private router: Router,
    private bsModalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.registerEvents();
    this.setItemRole();
  }

  private setItemRole(): void {
    switch (this.eventType) {
      case AssignmentEventType.Modal:
      case AssignmentEventType.RouteToDetails:
      case AssignmentEventType.Checkbox:
        this.itemRole = 'button'
        break;
      default:
        break;
    }
  }

  protected onAssignmentClick(assignment: AssignmentsOverviewModel): void {
    if (this.assignmentEventType == null) return;

    switch (this.eventType) {
      case AssignmentEventType.Modal:
        this.openModal(assignment);
        break;
      case AssignmentEventType.RouteToDetails:
        this.routeToAssignment(assignment.id!);
        break;
      case AssignmentEventType.Checkbox:
        this.toggleAssignmentSelection(assignment);
        this.assignmentsSelected;
        break;
      default:
        break;
    }
  }

  protected isAssignmentOnSameDay(from: Date | undefined, to: Date | undefined): boolean {
    if (!from || !to) return false;
    return new Date(from).toDateString() === new Date(to).toDateString();
  }

  protected isAssignmentSelected(assignmentId: number): boolean {
    return this.selectedAssignments.has(assignmentId);
  }

  protected toggleAssignmentSelection(assignment: AssignmentsOverviewModel): void {
    if (this.selectedAssignments.has(assignment.id)) {
      this.selectedAssignments.delete(assignment.id);
    } else {
      this.selectedAssignments.add(assignment.id);
    }

    let selectedAssignmentList: AssignmentsOverviewModel[] = [];

    if (this.assignmentSubject?.value != null) {
      selectedAssignmentList = Array.from(this.selectedAssignments).map(id =>
        (this.assignmentSubject?.value as AssignmentsOverviewModel[]).find(a => a.id === id)
      ) as AssignmentsOverviewModel[];
    }
    this.assignmentsSelected.emit(selectedAssignmentList);
  }

  protected getRouterLink(assignment: AssignmentsOverviewModel): string | null {
    if (this.eventType != AssignmentEventType.RouteToDetails) return null;
    return `/assignments/${assignment.id}`;
  }

  private routeToAssignment(assignmentId: number): void {
    this.router.navigate(['/assignments', assignmentId]);
  }

  private openModal(assignment: AssignmentsOverviewModel): void {
    const initialState = { assignment };
    this.bsModalService.show(AssignmentSummaryModalComponent, { initialState });
  }

  private resetCheckboxes(): void {
    this.selectedAssignments.clear();
  }

  private registerEvents(): void {
    this.assignmentSubject!.subscribe(() => {
      this.resetCheckboxes();
    })
  }
}
